<template>
  <mdb-container>
    <div class="text-right">
      <router-link to="/admin/partnerManage">
        <mdb-btn outline="info" size="md" class="my-3" icon="arrow-left">
          뒤로가기
        </mdb-btn>
      </router-link>
    </div>
    <mdb-card>
      <h3 class="card-header unique-color white-text text-center font-weight-bold text-uppercase py-2 ">협력사 등록</h3>
      <div class="card-body">
        <label for="orderNumber" class="grey-text">순번</label>
        <input type="text" id="orderNumber" class="form-control" v-model="order_number" placeholder="0">
        <br>
        <label for="partnerName" class="grey-text">기업명</label>
        <input type="text" id="partnerName" class="form-control" v-model="partner_name">
        <br>
        <label for="homepage" class="grey-text">홈페이지 주소</label>
        <input type="text" id="homepage" class="form-control" v-model="homepage">
        <mdb-select multiple @getValue="getSelectValue" :options="sel_fields" label="분야를 선택하세요."/>
        <div class="text-center mt-1">
          <mdb-file-input btnColor="info" @getValue="getFileInputValue" img/>
        </div>
        <div class="text-center mt-1">
          <mdb-btn outline="success" @click="onImageUpload()" icon="pen">등록</mdb-btn>
        </div>
        <!-- Default form contact -->
      </div>
      <!-- Card body -->
    </mdb-card>
  </mdb-container>
</template>

<script>
import {mdbCard, mdbSelect, mdbContainer, mdbBtn, mdbFileInput} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: "PartnerRegistration",
  data() {
    return {
      fileList: [],
      id: "",
      logo: "",
      partner_name: "",
      order_number: "",
      homepage: "",
      sel_fields: [
        {text: '드론', value: '드론'},
        {text: '웹', value: '웹'},
        {text: '앱', value: '앱'},
        {text: '펌웨어', value: '펌웨어'},
        {text: '하드웨어', value: '하드웨어'}
      ],
      partners: [],
      fields: "",
    }
  },
  components: {
    mdbCard,
    mdbSelect,
    mdbContainer,
    mdbBtn,
    mdbFileInput,
  },
  methods: {
    getSelectValue(value) {
      const self = this;
      self.fields = value;
    },
    getFileInputValue(val) {
      const self = this;
      self.fileList = val;
    },
    onImageUpload() {
      const self = this;
      if (self.fileList.length === 0) {
        alert('로고 이미지를 등록해주세요.');
        return;
      }
      const db = firebase.firestore();
      const storage = firebase.storage();

      const file = self.fileList[0];
      const storageRef = storage.ref();
      const savePath = storageRef.child('partners/' + file.name);
      const upload = savePath.put(file)

      upload.on('state_changed',
          // 변화시 동작하는 함수
          null,
          //에러시 동작하는 함수
          (error) => {
            console.error('실패사유는', error);
          },
          // 성공시 동작하는 함수
          () => {
            upload.snapshot.ref.getDownloadURL().then((url) => {
              // console.log('업로드된 경로는', url);

              if(self.order_number === ''){
                self.order_number = 0
              }

              const data = {
                partner_name: self.partner_name,
                order_number: self.order_number,
                homepage: self.homepage,
                fields: self.fields,
                logo: url,
                reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
              }
              db.collection('Partners').add(data).then((result) => {
                // 성공 후 실행할 코드
                alert('협력사 등록이 완료되었습니다.');
                this.$router.push('/admin/partnerManage').catch((err) => {
                  console.log(result.id)
                  console.log(err)
                })
              }).catch((err) => {
                // 실패 후 실행할 코드
                console.log(err)
              })
            });
          }
      );
    },
  },
}
</script>

<style scoped>

</style>